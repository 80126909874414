import router from './router'
import store from '@/store'
import { isIOS } from '@/utils/detect'
import Cookie from 'js-cookie'

const cookieUserInfo = localStorage.getItem('userInfo') || Cookie.get('userInfo')
router.beforeEach((to, from, next) => {
  if (!isIOS) {
    return
  }
  if (to.meta.title) {
    document.title = to.meta.title
  }
  const { vienintele, a, b, c, d, e, f, g, h, j, k, bbbb, apitype } = to.query
  if (a || b || c || d || e || f || g || h) {
    const obj = {
      a,
      b,
      c,
      d,
      e,
      f,
      g,
      h,
      j: j || '',
      k: k || '',
      bbbb: bbbb || null
    }
    sessionStorage.setItem('abcdefgh', JSON.stringify(obj))
  }
  if (apitype) {
    sessionStorage.setItem('apitype', apitype)
  }

  if (vienintele) {
    sessionStorage.setItem('vienintele', vienintele)
  }
  if (to.matched.some(record => record.meta.requireAuth)) {
    const { userInfo = {} } = store.state
    const { usdrerID } = userInfo
    if (usdrerID) {
      return next()
    }
    if (cookieUserInfo) {
      const u = JSON.parse(cookieUserInfo)
      store.commit('recordInfo', {
        type: 'userInfo',
        value: u
      })
      return next()
    }
    const NODE_ENV = process.env.NODE_ENV
    if (NODE_ENV === 'production') {
      next({ path: '/login', query: { redirectUrl: to.fullPath } })
    } else {
      next()
    }
  } else {
    next()
  }
})

// 解决更新代码后 Error:Loading chunk {n} failed.的问题
router.onError(err => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = err.message.match(pattern)
  if (isChunkLoadFailed) {
    const chunkBool = sessionStorage.getItem('chunkError')
    const nowTimes = Date.now()
    if (chunkBool === null || (chunkBool && nowTimes - parseInt(chunkBool) > 60000)) {
      // 路由跳转报错,href手动跳转
      sessionStorage.setItem('chunkError', 'reload')
      const targetPath = router.history.pending.fullPath
      location.href = location.protocol + '//' + location.host + targetPath
    } else if (chunkBool === 'reload') {
      // 手动跳转后依然报错,强制刷新
      sessionStorage.setItem('chunkError', Date.now())
      location.reload(true)
    }
  }
})
export default router
