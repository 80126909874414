<template>
  <div class="header" :style="{ color, backgroundColor: bgColor }">
    <div class="content">
      <div v-if="showBackButton" @click="goBack" class="back-btn">
        <img src="@/assets/img/header-back-btn.png" />
      </div>
      <div v-if="showLogOut" @click="visibleLogOut = true" class="back-btn">
        Keluar
      </div>
      <div class="header_title">{{ title }}</div>
      <div
        v-if="showCustomerService"
        class="right-block"
        @click="showCustomerServiceModal"
      >
        Customer Service
      </div>
    </div>
    <van-dialog
      v-model="visible"
      :showConfirmButton="false"
      close-on-click-overlay
    >
      <div class="customer-service-body">
        <img class="kefu_bg" src="@/assets/img/kefu_bg.png" alt="" />
        <p class="title">Customer Service</p>
        <span class="Email">danaroket@outlook.com</span>
        <button type="button" class="confirm-btn" @click="onCloseDialog()">
          Dapat
        </button>
      </div>
    </van-dialog>
    <van-dialog
      v-model="visibleLogOut"
      show-cancel-button
      cancel-button-text="Batal"
      confirm-button-text="Setuju"
      @cancel="visibleLogOut = false"
      @confirm="logOut"
    >
      <h3 class="logout_text">yakin untuk keluar?</h3>
    </van-dialog>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import dialogVisible from '@/mixins/dialogVisible'
import store from '@/store'
import router from '@/router'

export default {
  name: 'BaseHeaderBar',
  mixins: [dialogVisible],
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  props: {
    title: {
      type: String,
      default: 'Dana Roket'
    },
    showCustomerService: {
      type: Boolean,
      default: true
    },
    showBackButton: {
      type: Boolean,
      default: false
    },
    showLogOut: {
      type: Boolean,
      default: false
    },
    color: {
      type: String
    },
    bgColor: {
      type: String
    }
  },
  data () {
    return {
      visibleLogOut: false
    }
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    showCustomerServiceModal () {
      this.visible = true
    },
    // 退出登录
    logOut () {
      store.commit('recordInfo', {
        type: 'userInfo',
        value: {}
      })
      localStorage.removeItem('userInfo')
      router.push({
        path: '/login'
      })
    }
  }
}
</script>

<style scoped lang="less">
.header {
  position: relative;
  // width: 100%;
  height: 88px;
  color: #fff;
  text-align: center;
  font-size: 34px;
  font-weight: 600;
  z-index: 3;
  .content {
    position: fixed;
    left: 0;
    right: 0;
    background: #553abb;
  }
  .header_title {
    height: 88px;
    line-height: 88px;
  }
}

.back-btn {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 30px;
  cursor: pointer;

  img {
    width: 21px;
    height: 34px;
  }
}

.right-block {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 25px;
  color: #000;
  cursor: pointer;
  font-weight: 600;
  color: #fff;
  background: #7761c9;
  padding: 15px 15px;
  border-radius: 50px;
}
.customer-service-title {
  position: relative;

  p {
    .dialog-title;
  }

  .close-icon {
    position: absolute;
    top: 50%;
    right: 32px;
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.customer-service-body {
  padding: 32px 25px 54px;
  .kefu_bg {
    width: 473px;
    height: 331px;
    position: absolute;
    top: -250px;
    left: 50%;
    transform: translateX(-50%);
  }
  p {
    margin: 0;
  }
  .title {
    color: #fff;
    font-size: 54px;
    margin-bottom: 50px;
    margin-top: 90px;
  }
  .Email,
  .text {
    margin-bottom: 50px;
    font-size: 34px;
    font-weight: 600;
    color: #ffffff;
    line-height: 24px;
  }
  .confirm-btn {
    .submit-btn;
    width: 500px;
    margin-top: 50px;
  }
}
.logout_text {
  color: #000;
  font-size: 46px;
  margin: 60px 0px;
}
</style>
