const Test = () => import('@/views/test')

export default [

  {
    path: '/test',
    component: Test,
    meta: { requireAuth: false }
  }
]
